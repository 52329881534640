import React, { useEffect, useState } from "react";
import resumeFile from "../documents/moataz-mostafa-ansary-cv.pdf";
import { useInView } from "react-intersection-observer";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "1999 - 2002",
      title: "B.Sc in Accounting, Faculty of Commerce",
      place: "University Of Alexandria, Egypt",
      desc: "Gained comprehensive knowledge in financial management, auditing, and taxation principles to support businesses.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "11/2005 - current",
      title: "Information Technology Manager",
      place: "Natgas co. One of EKH Companies in Egypt",
      desc: "Managed project teams in Agile environment, realizing success through application of SDLC methodologies and exceptional leadership skills. Led and managed staff of IT team members engaged in administering complex IT infrastructure.",
    },
    {
      yearRange: "2/2005 - 8/2005",
      title: "System Administrator",
      place: "Future soft, Egypt",
    },
    {
      yearRange: "2003 - 2004",
      title: "Network Administrator",
      place: "Nordex Egypt for Computer, Egypt",
    },
    {
      yearRange: "2002 - 2003",
      title: "Technical Support",
      place: "Kasten Steel Company, Egypt",
    },
  ];

  const skills = [
    { name: "Budgeting and Forecasting", percent: 95 },
    { name: "Project Management", percent: 97 },
    { name: "Goal-oriented", percent: 100 },
    { name: "Agile Software", percent: 85 },
    { name: "Scrum Management", percent: 89 },
    { name: "Leadership", percent: 100 },
    { name: "Documentation and Reporting", percent: 93 },
    { name: "Risk Mitigation Planning", percent: 96 },
    { name: "Quality Assurance", percent: 97 },
    { name: "Security Planning", percent: 88 },
    { name: "Agile Framework", percent: 93 },
    { name: "Budget Administration", percent: 90 },
    { name: "Project Lifecycle Management", percent: 95 },
    { name: "Infrastructure Development", percent: 87 },
    { name: "Requirements Analysis", percent: 90 },
    { name: "Product Development", percent: 89 },
    { name: "Self-Motivated", percent: 92 },
    { name: "Teambuilding", percent: 94 },
    { name: "Decision-Making", percent: 95 },
    { name: "PPE Compliance", percent: 88 },
    { name: "Problem-Solving", percent: 95 },
    { name: "Cultural Awareness", percent: 90 },
    { name: "Interpersonal Communication", percent: 92 },
    { name: "Attention to Detail", percent: 94 },
  ];

  const { ref: skillsRef, inView: skillsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [animatedPercents, setAnimatedPercents] = useState(
    new Array(skills.length).fill(0)
  );

  useEffect(() => {
    if (skillsInView) {
      const duration = 200; // duration in ms
      const increments = skills.map((skill) => skill.percent);
      const newAnimatedPercents = new Array(skills.length).fill(0);

      increments.forEach((end, index) => {
        let start = 0;
        const incrementTime = duration / end;
        const interval = setInterval(() => {
          if (start < end) {
            start++;
            newAnimatedPercents[index] = start;
            setAnimatedPercents([...newAnimatedPercents]);
          } else {
            clearInterval(interval);
          }
        }, incrementTime);
      });
    }
  }, [skillsInView]);

  return (
    <section id="resume" className="section bg-light">
      <div className="container">
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        <div className="row g-5 mt-5">
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.map((value, index) => (
                <div key={index}>
                  <h3 className="text-5">{value.title}</h3>
                  <p className="mb-2">
                    {value.place} / {value.yearRange}
                  </p>
                  <p className="text-muted">{value.desc}</p>
                  <hr className="my-4" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.map((value, index) => (
                <div key={index}>
                  <h3 className="text-5">{value.title}</h3>
                  <p className="mb-2">
                    {value.place} / {value.yearRange}
                  </p>
                  <p className="text-muted">{value.desc}</p>
                  <hr className="my-4" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5">My Skills</h2>
        <div className="row gx-5" ref={skillsRef}>
          {skills.map((skill, index) => (
            <div className="col-md-6" key={index}>
              <p className="fw-500 text-start mb-2">
                {skill.name}{" "}
                <span className="float-end">{animatedPercents[index]}%</span>
              </p>
              <div className="progress progress-sm mb-4">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: skillsInView ? `${animatedPercents[index]}%` : "0%",
                    transition: "width 1s ease-in-out",
                  }}
                  aria-valuenow={animatedPercents[index]}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
