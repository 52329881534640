import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-5 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        {/* <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2> */}
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Moataz Mostafa Ansary
              </span>
            </h2>
            <p className="text-5">
              Insightful Information Technology Manager with more than 22 years
              of IT leadership experience including oversight of infrastructure,
              application support and security services. Proven leader in
              directing operations, maintenance and support of complex systems.
              Develops creative business solutions, leveraging diverse
              methodologies and delivering engineering solutions for leading
              organizations. Highly adept in request for proposal development,
              technology needs assessments and staff training.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  22
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experience</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-12 col-lg-3 text-center text-lg-start wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              Moataz Mostafa Ansary
            </p>
          </div>
          <div
            className="col-12 col-lg-3 text-center text-lg-start wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:moataz_ansary@yahoo.com">
                moataz_ansary@yahoo.com
              </a>
            </p>
          </div>
          <div
            className="col-12 col-lg-3 text-center text-lg-start wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">22 January, 1982</p>
          </div>
          {/* <div
            className="col-12 col-lg-3 text-center text-lg-start wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Egypt</p>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
